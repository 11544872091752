export const getHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'x-customer-id': localStorage.getItem('kollel')
    }
}
export const getLoginHeaders = () => {
   return {
       'Content-Type': 'application/json',
       'x-customer-id': localStorage.getItem('kollel')
   }
}