import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import FrameworksTable from './framworks/FrameworksTable.jsx';
import Permission from './Permissions/Permission.jsx';
import CloseMonth from './month/CloseMonth.jsx';
import CustomToolbar from './toolBar/toolBar';
import {NewAttandanceTable} from "./Attandance/AttandanceTable2";
import {TravelsApprove} from "./day/travelsApprove/index";
import {Day} from "./day/Day";
import {Groups} from "./groups";

const drawerWidth = 240;

const Template = ({ children }) => {
    const [boxContent, setBoxContent] = useState(<NewAttandanceTable />);
    const [currentTab, setCurrentTab] = useState('נוכחות');
    const tabs = [
        {
            header: 'נוכחות',
            icon: <AccessAlarmIcon />,
            content: <><NewAttandanceTable /></>
        },
        {
            divider: true
        },
        {
            header: 'נסיעות',
            icon: <DirectionsBusFilledOutlinedIcon />,
            content: <TravelsApprove />
        },
        {
            header: 'כולל יום',
            icon: <LightModeOutlinedIcon />,
            content: <Day />
        },
        {
            divider: true
        },
        {
            header: 'חודשי דיווח',
            icon: <EventAvailableIcon />,
            content: <><CloseMonth /></>
        },
        {
            header: 'מסגרות',
            icon: <BorderAllIcon />,
            content: <><FrameworksTable /></>
        },
        {
            header: 'קבוצות',
            icon: <GroupAddOutlinedIcon />,
            content: <><Groups /></>
        },
        {
            header: 'הרשאות',
            icon: <AdminPanelSettingsOutlinedIcon />,
            content: <><Permission /></>
        },
    ];

    const handleListItemClick = (tab) => {
        setBoxContent(tab.content);
        setCurrentTab(tab.header);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <CustomToolbar />
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, mt: '64px' }}
            >
                {children}
                {boxContent}
            </Box>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        top: '64px' // Adjust to be below the toolbar
                    },
                }}
                variant="permanent"
                anchor="right"
            >
                <Divider/>
                <List style={{padding: 0}}>
                    {tabs.map((tab, index) => {
                        if (!tab.divider) {
                            return (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton selected={tab.header === currentTab} onClick={() => handleListItemClick(tab)}>
                                        <ListItemIcon>{tab.icon}</ListItemIcon>
                                        <ListItemText primary={tab.header} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        } else {
                            return <Divider key={index} />;
                        }
                    })}
                </List>
            </Drawer>
        </Box>
    );
};

export default Template;