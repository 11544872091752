import { observer } from "mobx-react";
import { Button } from "@mui/material";
import React from "react";
import { useDayStore } from "../store/dayStore";
import exceljs from "exceljs";
import { saveAs } from 'file-saver';

export const ExportToExcel = observer(() => {
    const { dayStore } = useDayStore();

    const convertToExcel = () => {
        const workbook = new exceljs.Workbook();
        const regularWorksheet = workbook.addWorksheet('דוחות רגילים');
        const lateWorksheet = workbook.addWorksheet('דוחות מאוחרים');

        const columns = [
            { header: "ת.ז.", key: "tz" , width: 10 },
            { header: "שם", key: "name", width: 17},
            { header: "שנה", key: "year" },
            { header: "חודש", key: "monthName" },
            { header: "נסיעות", key: "travels" },
            { header: "שמחות", key: "joys" },
            { header: "חבורות", key: "speech" },
            { header: "הערות", key: "comment" },
            { header: "מאוחר", key: "late" },
        ];

        regularWorksheet.columns = columns;
        lateWorksheet.columns = columns;

        dayStore.getMapData.forEach(item => {
            const row = {...item, travels: !['לא מאושר', 'ממתין לאישור'].includes(item.travels)? item.travels: null}

            if (row.late) {
                lateWorksheet.addRow(row);
            } else {
                regularWorksheet.addRow(row);
            }
        });

        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const date = new Date().toLocaleString('he-IL', { dateStyle: 'short', timeStyle: 'short' }).replace(/[/:]/g, '_');
            saveAs(blob, `דוחות_כולל_יום_${date}.xlsx`);});
    };

    return (
        <Button style={{ maxHeight: "30px", direction: "rtl", margin: "15px" }} variant="contained" onClick={convertToExcel}>
            ייצא ל excel
        </Button>
    );
});