import React, {useMemo} from "react";
import {observer} from "mobx-react";
import {useDayStore} from "../../store/dayStore";
import {MenuOptions} from "./menuOptions";
import {hebrewComparator} from '../../utils/utils';
import GenericTable from "../../genericTable";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import '../dayTableStyles.css';

const Table = observer(() => {
    const {dayStore, fetchData} = useDayStore();

    const columnDefs = useMemo(() => [
        {headerName: "", field: "options", cellRenderer: (data) => <MenuOptions data={data}/>},
        {headerName: "ת.ז.", field: "tz", sortable: true, filter: true},
        {headerName: "שם", field: "name", sortable: true, filter: true, comparator: hebrewComparator},
        {headerName: "שנה", field: "year", sortable: true, filter: true},
        {headerName: "חודש", field: "monthName", sortable: true, filter: true},
        {
            headerName: "סטטוס",
            field: "approved",
            sortable: true,
            filter: true,
            cellRenderer: (params) => {
                if (params.value) {
                    return <CheckIcon className='travel-status-icon approved'/>;
                } else if (params.value === 0) {
                    return <CloseIcon className='travel-status-icon not-approved'/>;
                } else {
                    return '';
                }
            }
        },
        {headerName: "סכום להחזר", field: "sum", sortable: true, filter: true},
        {headerName: "הערות", field: "comment", sortable: true, filter: true, comparator: hebrewComparator},
        {headerName: "מאוחר", field: "late", sortable: true, filter: true},
    ], []);

    const rowData = useMemo(() =>
        dayStore.filterTravels.map(row => {
            const {firstName, lastName, lateMonthName, lateYear, ...rest} = row;
            return {
                ...rest,
                name: `הרב ${lastName} ${firstName}`,
                late: lateMonthName && lateYear ? `${lateMonthName}, ${lateYear}` : null
            };
        }), [dayStore.filterTravels]
    );

    return (
        <GenericTable
            rowData={rowData}
            columnDefs={columnDefs}
            fetchData={fetchData}
            clear={() => {
                dayStore.setDayFilters([])
            }}
        />
    );
});

export default Table;