import {MenuItem} from "@mui/material";
import React from "react";
import {Delete} from "@mui/icons-material";
import {useDayStore} from "../../../store/dayStore";
import Edit from "@mui/icons-material/Edit";

export const DeleteCom = ({data}) => {
    const {data:{id}} = data
    const { deleteTravel } = useDayStore();
    return (
        <MenuItem onClick={async () => {
            await deleteTravel(id)

        }} className='menuButton'>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Delete/>
                <p style={{margin: '0 0 0 8px'}}> מחיקה </p>
            </div>
        </MenuItem>
    );
}