import IconButton from "@mui/material/IconButton";
import {Edit} from "@mui/icons-material";
import React from "react";

export const EditGroup = ({data, editHandleOpen}) => {

    return <IconButton aria-label="edit" className="icon-button" onClick={async () => {
        await editHandleOpen(data);
    }}>
        <Edit></Edit>
    </IconButton>
}