import { MenuItem } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {useDayStore} from "../../../store/dayStore";
import Edit from "@mui/icons-material/Edit"; // Import the icon

export const DisapprovedCom = ({data}) => {
    const {data:{id}} = data
    const { updateTravel } = useDayStore();
    return (
        <MenuItem onClick={async () => {
            await updateTravel({id, approved: false});
            // disapprove(data); // Add your disapprove logic here
        }} className='menuButton'>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <CloseIcon/>
                <p style={{margin: '0 0 0 8px'}}> דחיה </p>
            </div>
        </MenuItem>
    );
}