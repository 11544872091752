import GenericTable from "../genericTable";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import React, {useEffect, useState} from "react";
import {DeleteGroupComponent} from "./delete";
import {EditGroup} from "./edit";
import {GroupDialog} from "./groupDialog";
import {Button, Modal} from "@mui/material";

export const Table = () => {
    const [rowData, setRowData] = useState([]);
    const fetchWithAuth = useFetchWithAuth();
    const [fetch, setFetch] = useState(false);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(null);

    const addGroup = () => {
        setEditData(null);
        setOpen(true);
    }

    useEffect(() => {
        if(!fetch) return;
        fetchData().then(() => setFetch(false))
    }, [fetch]);

    const openEdit = async (data) => {
        setEditData(data);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setEditData(null);
        setFetch(true);
    }

    const columnDefs = [
        { headerName: "שם קבוצה", field: "name", sortable: true, filter: true, },
        { headerName: "סדר", field: "time", sortable: true, filter: true, },
        {
            headerName: "",
            field: "edit",
            cellRenderer: (data) => <EditGroup data={data} editHandleOpen={openEdit}/>,
        },
        {
            headerName: "",
            field: "delete",
            cellRenderer: (data) => <DeleteGroupComponent data={data} setFetchData={setFetch} />,
        }
    ]

    const fetchData = async () => {
        const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/groups`, {
            method: "GET"
        })
        if (response.ok) {
            const message = await response.json()
            setRowData(message)
        }
    }

    return(
        <>
            <Button style={{maxHeight: "30px", direction: "rtl", marginBottom: "5px"}} variant="contained"
                    onClick={addGroup}>הוסף קבוצה</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <GroupDialog
                    handleClose={handleClose}
                    mode={editData ? 'edit' : 'create'}
                    params={editData}
                    setFetchData={setFetch}
                />
            </Modal>
            <GenericTable
                rowData={rowData}
                fetchData={fetchData}
                columnDefs={columnDefs}
            />
        </>
    )
}