import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router";
import LogoButton from "./logoButton";

const CustomToolbar = () => {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    return (
        <AppBar
            position="fixed"
            sx={{ width: '100%', mr: 0 }}
            className="custom-toolbar"
        >
            <Toolbar>
                <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                    מערכת ניהול רשת הכוללים
                </Typography>
                <Box sx={{ flexGrow: 1 }} />

                <Button
                    variant="contained"
                    onClick={logout}
                    color='inherit'
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        התנתקות
                        <LogoutIcon />
                    </Box>
                </Button>
                <LogoButton />
            </Toolbar>
        </AppBar>
    );
};

export default CustomToolbar;