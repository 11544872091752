import { action, computed, makeAutoObservable } from "mobx";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import { paymentFilter } from "../filters/paymentFilter";
import { lateFilter } from "../filters/lateFilter";

class DayStore {
    data = [];
    dayFilters = [];
    dayTravelsFilters = [];

    constructor() {
        makeAutoObservable(this, {
            getPassengerTravels: computed,
            getDays: computed,
            getDayFilters: computed,
            setDayFilters: action,
            getFilteredData: computed,
            getYears: computed,
            setData: action,
            getMapData: computed,
            setTravelsFilters: action,
            getTravelsFilters: computed,
            filterTravels: computed
        });
    }

    setData(newData) {
        this.data = newData;
    }

    get getYears() {
        const years = this.getDays.map(row => row.year);
        return Array.from(new Set(years));
    }

    get getFilteredData() {
        console.log(2);
        return this.filterData(this.dayFilters, this.getDays);
    }

    get filterTravels() {
        console.log(this.dayTravelsFilters);
        return this.filterData(this.dayTravelsFilters, this.getPassengerTravels);
    }

    filterData(filters, data) {
        return data.filter(day => {
            if (!filters.length) return true;
            return filters.reduce((acc, { fieldName, values }) => {
                if (!acc) return acc;
                if (fieldName === 'payment') return paymentFilter(values.year, values.month, day);
                if (fieldName === 'late') return lateFilter(values, day);
                if (!values.length) return acc;
                return acc && values.includes(day[fieldName]);
            }, true);
        });
    }

    get getMapData() {
        return this.getFilteredData.map(row => {
            const { firstName, lastName, lateMonthName, lateYear, tz, ...rest } = row;
            return {
                ...rest,
                name: `הרב ${lastName} ${firstName}`,
                late: lateMonthName && lateYear ? `${lateMonthName}, ${lateYear}` : null,
                tz: +tz
            };
        });
    }

    get getPassengerTravels() {
        return this.data.filter(day => {
            const { travels } = day;
            return travels.length === 1 && travels[0].type === 'passenger';
        }).map(day => {
            const { joys, speech, travels, ...rest } = day;
            return {
                ...rest,
                ...travels[0]
            };
        });
    }

    get getDays() {
        return this.data.map(day => {
            const { travels, speech, joys, ...rest } = day;
            return {
                ...rest,
                ...(travels && { travels: this.getTravels(day) }),
                ...(joys && { joys: joys.reduce((acc, joy) => acc + +joy.sum, 0) || null }),
                ...(speech && { speech: +speech.sum || null })
            };
        });
    }

    getTravels(day) {
        const { travels } = day;
        if (travels?.length === 1) {
            const { type, approved, sum } = travels[0];
            if (type !== 'passenger' || approved) {
                return +sum;
            }
            if (approved === 0) {
                return 'לא מאושר';
            } else {
                return 'ממתין לאישור';
            }
        }
        return travels.reduce((acc, travel) => acc + +travel.sum, 0) || null;
    }

    setDayFilters(filters) {
        this.dayFilters = filters;
    }

    setTravelsFilters(filters) {
        console.log(1);
        this.dayTravelsFilters = filters;
    }

    get getDayFilters() {
        return this.dayFilters;
    }

    get getTravelsFilters() {
        return this.dayTravelsFilters;
    }
}

const dayStore = new DayStore();

export const useDayStore = () => {
    const fetchWithAuth = useFetchWithAuth();

    const fetchData = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/day-attandance/get-days`, {
                method: 'GET'
            });
            const data = await response.json();
            dayStore.setData(data);
        } catch (error) {
            console.error("Failed to fetch data", error);
        }
    };

    const updateTravel = async (data) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/day-attandance/update-travel`, {
                method: 'PUT',
                body: JSON.stringify(data)
            });
            if (response.status === 200) {
                await fetchData();
            } else {
                console.error("Failed to approve travel", response);
            }
        } catch (error) {
            console.error("Failed to approve travel", error);
        }
    };

    const deleteTravel = async (id) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/day-attandance/delete-travel/${id}`, {
                method: 'DELETE'
            });
            if (response.status === 200) {
                await fetchData();
            } else {
                console.error("Failed to delete travel", response);
            }
        } catch (error) {
            console.error("Failed to delete travel", error);
        }
    };

    return {
        updateTravel,
        dayStore,
        fetchData,
        deleteTravel
    };
};