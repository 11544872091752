import React, {useEffect, useState} from 'react'
import {AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {Box, Button, CircularProgress, Modal} from '@mui/material';
import * as XLSX from 'xlsx';
import {ErrorOutline} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {Edit, Close} from '@mui/icons-material';
import EditStudentPermissions from './EditStudentPermissions';
import {MissingHeaders} from "./missingHeaders";
import {hebrewComparator} from "../utils/utils";
import useFetchWithAuth from "../hooks/useFetchWithAuth";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    maxHeight: "70vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    paddingTop: "5px"
};

const Permission = () => {

    const [rowData, setRowData] = useState([])
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState()
    const [fileName, setFileName] = useState("")
    const [load, setLoad] = useState(false)
    const [frameworks, setFrameworks] = useState([])
    const [editStudent, setEditStudent] = useState()
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
    };
    const [editOpen, setEditOpen] = useState(false)
    const [missingHeaders, setMissingHeaders] = useState(null)
    const editHandleOpen = () => {
        setEditOpen(true)
    }
    const editHandleClose = () => {
        setEditOpen(false)
    }
    const fetchWithAuth = useFetchWithAuth();


    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/frameworks`, {
                    method: "GET"
                })
                if (response.ok) {
                    const data = await response.json()
                    const rows = data.map((row) => {
                        return {...row, name: `${row.nameHe} (${row.nameEn})`}
                    })
                    setFrameworks(rows)
                }
            } catch {
                console.error("error on fetch data")
            }
        }
        fetchData()

    }, [])

    const handleFile = () => {

        handleClose()
        const file = selectedFile;
        const reader = new FileReader();
        reader.onload = async (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, {type: 'array'});
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(sheet, {header: 1});
            const dataHeaders = excelData[0];
            const missingHeaders = validateNameHeaders(dataHeaders)
            if (!missingHeaders.length) {
                const excelObjects = excelData.slice(1).map(row => {
                    return dataHeaders.reduce((obj, header, index) => {
                        obj[header] = row[index];
                        return obj;
                    }, {});
                });
                const transformedData = excelObjects.filter(obj => obj['שם'] && !Object.keys(obj).find(key => obj[key] === 'סה"כ')).map(obj => {
                    const [description, lastName, ...firstNameArray] = obj['שם'] ? obj['שם'].replace("'", "").split(' ') : ["", "", ""]
                    const firstName = firstNameArray.join(" ")
                    const frameworks = obj["מסגרת"]?.split(',').map(f => f.trim())
                    obj['ת.ז.'] = String(obj['ת.ז.']).slice(0, 9)
                    const groupMorning = obj['חבורה סד"א']
                    const groupNoon = obj['חבורה סד"ב']
                    return ({ tz: obj['ת.ז.'], description, lastName, firstName, frameworks, groupMorning, groupNoon})
                }).filter(obj => obj)
                for (let index = 0; index < transformedData.length; index += 100) {

                    const chunk = transformedData.slice(index, index + 100)
                    try {
                        const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/permissions/setPermissions`, {
                            method: "POST",
                            body: JSON.stringify({
                                datasetId: index / 100,
                                chunk,
                                isFinlize: index + 100 >= transformedData.length
                            })
                        })
                        if (index + 100 >= transformedData.length) {
                            const newdata = await response.json()
                            setRowData(newdata.map((d) => ({
                                ...d,
                                name: `${d.description} ${d.lastName} ${d.firstName}`
                            })))
                        }
                        setSelectedFile(null)

                    } catch {
                        console.error("error on updae pemissions")
                    }
                }
                setLoad(false)
                setMissingHeaders(null)
            } else {
                setSelectedFile(null)
                setLoad(false)
                setMissingHeaders(missingHeaders)
            }


        };
        reader.readAsArrayBuffer(file);
    };

    const EditComponent = (params) => {
        return <IconButton aria-label="delete" className="icon-button" onClick={() => {
            editHandleOpen();
            setEditStudent(params.data)
        }}>
            <Edit></Edit>
        </IconButton>
    }

    const columnDefs = [
        {field: "studentTz", headerName: "ת.ז.", sortable: true, filter: true},
        {field: "name", headerName: "שם" , sortable: true, filter: true, comparator: hebrewComparator},
        {field: "allowedFrameworks", headerName: "מסגרות", width: "280" , sortable: false, filter: true, comparator: hebrewComparator},
        {field: "groupMorning", headerName: "חבורה סדר א׳", width: "280" , sortable: true, filter: true, comparator: hebrewComparator},
        {field: "groupNoon", headerName: "חבורה סדר ב׳", width: "280" , sortable: true, filter: true, comparator: hebrewComparator},
        {
            headerName: "",
            field: "edit",
            cellRenderer: EditComponent,
            width: "80px",
            sortable: false,
            filter: false,

        }
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/permissions`, {
                    method: "GET",
                });
                if (response.ok) {
                    const data = await response.json();
                    const fixData = data.map((d) => ({...d, name: `${d.description} ${d.lastName} ${d.firstName} `}))
                    setRowData(fixData);
                }
            } catch {
                console.error("error on fetch data")
            }
        };

        fetchData();
    }, [])

    const approve = (e) => {
        setMissingHeaders(null)
        const file = e?.target?.files ? e.target.files[0] : null
        setFileName(file ? file.name : '')
        setLoad(true)
        setSelectedFile(file)
        handleOpen()

        const fileInput = e.target;
        setFileName(file ? file.name : '');
        e.target.value = null; // Reset the value of the file input
        setLoad(true);
        e.target.value = null; // Reset the value of the file input
        handleOpen();
    }

    useEffect(() => {
        if (selectedFile) handleOpen()
    }, [selectedFile])

    const validateNameHeaders = (headers) => {
        return ['שם','חבורה סד"א','חבורה סד"ב','ת.ז.','מסגרת'].filter(name => !headers.filter(h => h === name).length)
    }

    return (

        <div style={{direction: "rtl"}}>


            <div style={{direction: "ltr"}}>
                <input
                    accept=".xlsx, .xls"
                    style={{display: "none"}}
                    id="contained-button-file"
                    type="file"
                    onChange={approve}
                />
                <label htmlFor="contained-button-file">
                    <Button
                        variant="contained"
                        component="span" // Use "span" component to wrap the button
                        style={{maxHeight: "30px", direction: "rtl", marginBottom: "5px"}}
                        disabled={load}
                    >
                        בחר קובץ
                    </Button>
                </label>
            </div>

            <div>
                {
                    load ? <div style={{position: "absolute", top: "60vh", right: "55vw"}}>
                            <CircularProgress></CircularProgress></div>:
                        <>
                            <MissingHeaders missingHeaders={missingHeaders} open={missingHeaders?.length} setOpen={setMissingHeaders}/>
                            <div className="ag-theme-alpine" style={{
                                height: 'calc(100vh - 170px)',
                                direction: "rtl",
                                width: '93%'
                            }}>
                                <AgGridReact enableCellTextSelection={true} ensureDomOrder={true} rowData={rowData}
                                             columnDefs={columnDefs} gridOptions={{enableRtl: true}}
                                             defaultColDef={{sortable: true, filter: true}}></AgGridReact>
                            </div>
                        </>
                }
            </div>
            <Modal
                open={open}
                onClose={() => {
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <ErrorOutline></ErrorOutline>
                    <p style={{direction: "rtl", textAlign: "center", textWrap: "balance"}}>

                        שים לב בפעולה זו הינך מוחק הרשאות קיימות עבור כלל המסגרות
                        הינך בטוח כי ברצונך להעלות את הקובץ <span style={{fontWeight: "bold"}}>{fileName}</span>

                    </p>


                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Button
                            variant="contained"
                            component="span" // Use "span" component to wrap the button
                            onClick={handleFile}
                            style={{maxHeight: "30px", direction: "rtl", margin: "5px"}}
                        >
                            אישור
                        </Button>

                        <Button
                            variant="contained"
                            component="span" // Use "span" component to wrap the button
                            onClick={handleClose}
                            style={{maxHeight: "30px", direction: "rtl", margin: "5px", backgroundColor: "white"}}
                        >
                            <span style={{color: "#1976d2"}}>ביטול</span>
                        </Button>
                    </div>
                </Box>

            </Modal>


            <Modal
                open={editOpen}
                onClose={editHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton onClick={editHandleClose} style={{marginLeft: "85%"}}><Close></Close></IconButton>


                    <EditStudentPermissions student={editStudent} frameworks={frameworks} setRowData={setRowData}
                                            close={editHandleClose}></EditStudentPermissions>
                </Box>
            </Modal>

        </div>

    )
}

export default Permission