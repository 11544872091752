import DayTable from "./dayTable";
import {DayFilters} from "./filters";
import {ExportToExcel} from "./exportToExecl";

export const Day = () => {
    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <DayFilters/>
                <ExportToExcel/>
            </div>
            <DayTable/>
        </>
    )
}