import React, { useEffect, useState, useRef } from 'react';
import { List, ListItemText, Checkbox, ListItemButton, ListItemIcon, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const CustomFilter = ({ onFilterChanged, fieldName, filters, filterName, clearFilters, width }) => {
  const [filterValue, setFilterValue] = useState({});
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const resultObject = {};
    filters?.forEach(str => {
      resultObject[str] = false;
    });
    setFilterValue(resultObject)
  }, [])



  const handleChange = (e,filter) => {
    const updatedFilterValue = {
      ...filterValue,
      [filter]: !filterValue[filter]
    };
    setFilterValue(updatedFilterValue);
    onFilterChanged(fieldName, updatedFilterValue, clearFilters);
  }



  useEffect(() => {
    // Notify the parent component about the filter change
    onFilterChanged(fieldName, filterValue, clearFilters);

  }, [filterValue]);

  useEffect(() => {

    if (clearFilters) {
      setOpen(false)
      const resultObject = {};
      filters.forEach(str => {
        resultObject[str] = false;
      });
      setFilterValue(resultObject)
    }

  }, [clearFilters])


  return (
    <div ref={dropdownRef}>
      <ListItemButton onClick={handleClick} style={{ backgroundColor: "#bbb4b4", marginRight: "5px", marginLeft: "5px", maxHeight: "30px", borderRadius: "3px", width: width ? width : "150px" }} >
        <ListItemText primary={filterName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit style={{ position: "absolute", marginLeft: "4px", width: width ? width : "170px", overflowY: "scroll", maxHeight: "65vh" , zIndex: 600}}>
        <List component="div" disablePadding>
          {filters?.map(filter => <ListItemButton  key={filter} onClick={(e) => handleChange(e,filter)} sx={{ pl: 4 }} style={{ backgroundColor: "rgb(222 216 216)", height: "40px" }}>
            <ListItemIcon >
              <Checkbox onChange={()=>{}} checked={filterValue[filter]} name={filter} />
            </ListItemIcon>
            <ListItemText primary={filter} />
          </ListItemButton>)}
        </List>
      </Collapse>
    </div>
  );
};

export default CustomFilter;