// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Varela Round, sans-serif',
  },
  spacing: 8, // Ensure spacing function is available
  palette: {
    mode: 'light', // Define mode to avoid undefined error
  },
});

export default theme;