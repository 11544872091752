import React, {useState}  from 'react';
import {Button, IconButton, Box} from '@mui/material';
import Close from '@mui/icons-material/Close';
import {YearAndMonth} from './yearAndMonth';
import {Hours} from './maxHoursFrameworks';
import {convertToHebrewLetters} from '../utils/utils';
import {ErrorMassage} from '../errorMassage';
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import {GroupsMonthDialog} from "./groups/groups";
import {JoysData} from "./joysData";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    high: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: '80%',
    p: 4,
    direction: "rtl",
    padding: '10px',
    overflowY: 'scroll'
};

export function MonthDialog({
                                handleClose,
                                frameworks,
                                year,
                                setYear,
                                month,
                                setMonth,
                                handleSubmit,
                                frameworksHours,
                                setFrameworksHours,
                                mode,
                                disable,
                                groups,
                                setGroups,
                                groupsData,
                                setGroupsData,
                                joys,
                                setJoys,
                            }) {
    const [errorPost, setErrorPost] = useState(false);
    const [errorMassage, setMassage] = useState('');
    const fetchWithAuth = useFetchWithAuth();
    const onSubmit = async (data) => {
        data.year = convertToHebrewLetters(year);
        data.monthName = month;
        data.MaxHours = frameworksHours;
        data.groupMonth = groupsData;
        const allData = {...data, ...JSON.parse(JSON.stringify(joys))};
        try {
            const url = mode === 'edit' ? `${process.env.REACT_APP_BASE_URL}/closeMonth/updateMonth` : `${process.env.REACT_APP_BASE_URL}/closeMonth/openNewMonth`;
            const method = mode === 'edit' ? 'PUT' : 'POST';
            const response = await fetchWithAuth(url, {
                method,
                body: JSON.stringify(allData)
            });

            if (!response.ok) {
                setErrorPost(true);
                try {
                    const error = await response.text();
                    if (error === 'month already closed')
                    {
                        setMassage(`חודש ${month + ' ' + convertToHebrewLetters(year)}  כבר קיים במערכת`);
                    }
                } catch {
                    setMassage("ארעה תקלה בשמירת הנתונים");
                }
            }
            else {
                handleClose();
            }
        } catch {
            console.error("error on submit");
        }
    };
    return (
        <Box sx={style}>
            <IconButton onClick={handleClose} style={{marginBottom: '10px'}}>
                <Close/>
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3 style={{
                    textAlign: 'center',
                    width: '100%',
                    marginTop: '0'
                }}>{mode === 'edit' ? 'עריכת חודש' : 'פתיחת חודש'}</h3>
                <YearAndMonth
                    year={year}
                    month={month}
                    setYear={setYear}
                    setMonth={setMonth}
                    disable={disable}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: '100%'
                }}>
                    <h4 style={{textAlign: 'center', width: '100%'}}>מספר שעות מקסימלי לכל מסגרת</h4>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%'
                    }}>
                        <Hours
                            style={{width: '100%', paddingBottom: '20px'}}
                            frameworks={frameworks}
                            frameworksHours={frameworksHours}
                            setFrameworksHours={setFrameworksHours}
                        />
                    </div>
                    <h4 style={{textAlign: 'center', width: '100%'}}>שמחות</h4>
                    <JoysData birth={joys.birth} wedding={joys.wedding} twins={joys.twins} driver={joys.driver}
                              setJoys={setJoys} barMitzvah={joys.barMitzvah}/>

                    <h4 style={{textAlign: 'center', width: '100%'}}>חבורות</h4>

                    <GroupsMonthDialog
                        groups={groups}
                        setGroup={setGroups}
                        groupsData={groupsData}
                        setGroupsData={setGroupsData}
                        style={{width: '100%', paddingBottom: '20px'}}
                        mode={mode}
                    />

                </div>
                <ErrorMassage error={errorMassage} setOpen={setErrorPost} open={errorPost}/>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <Button type="submit" style={{maxHeight: '30px', direction: 'rtl'}} variant="contained">
                        {mode === 'edit' ? 'ערוך' : 'פתח'}
                    </Button>
                </div>
            </form>
        </Box>
    );
}