import React, { useMemo } from "react";
import { observer } from "mobx-react";
import GenericTable from "../genericTable";
import {georgianToStringHebrewDate, hebrewComparator} from '../utils/utils';
import { useDayStore } from "../store/dayStore";
import './dayTableStyles.css';

const DayTable = observer(() => {
    const { dayStore, fetchData } = useDayStore();

    const columnDefs = useMemo(() => [
        { headerName: "ת.ז.", field: "tz", sortable: true, filter: true },
        { headerName: "שם", field: "name", sortable: true, filter: true, comparator: hebrewComparator },
        { headerName: "שנה", field: "year", sortable: true, filter: true },
        { headerName: "חודש", field: "monthName", sortable: true, filter: true },
        {
            headerName: "נסיעות",
            field: "travels",
            sortable: true,
            filter: true,
            cellRenderer: (params) => {
                const style = {
                    padding: '2px 4px',
                    display: 'inline-block',
                    textShadow: '0 0 12px rgba(255, 0, 0, 0.7)' // Larger colored shadow effect
                };

                if (params.value === "לא מאושר") {
                    return (
                        <span className="travel-status">
                            {params.value}
                        </span>
                    );
                }
                if (params.value === "ממתין לאישור") {
                    return (
                        <span  className="travel-status waiting" >
                            {params.value}
                        </span>
                    );
                }
                if ( ![null, undefined].includes(params.value)) {
                    return (
                        <span className="travel-status approved">
                            {params.value}
                        </span>
                    );
                }
                return params.value;
            }
        },
        { headerName: "שמחות", field: "joys", sortable: true, filter: true },
        { headerName: "חבורות", field: "speech", sortable: true, filter: true },
        { headerName: "הערות", field: "comment", sortable: true, filter: true, comparator: hebrewComparator },
        { headerName: "תאריך דיווח", field: "created_at", sortable: true, filter: true , valueFormatter: (params) =>georgianToStringHebrewDate(new Date(params.value)), sort: 'desc',sortOrder: 1},
        { headerName: "מאוחר", field: "late", sortable: true, filter: true },
    ], []);

    return (
        <GenericTable
            rowData={dayStore.getMapData}
            columnDefs={columnDefs}
            fetchData={fetchData}
            clear={()=>{
                dayStore.setDayFilters([])
            }}
        />
    );
});

export default DayTable;