import {Edit, Lock, LockOpen} from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import {Button, Modal} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {AgGridReact} from "ag-grid-react";
import {toHebrewJewishDate} from 'jewish-date';
import {getJewishDate} from "jewish-dates-core";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from 'react-hook-form';
import {MonthDialog} from "./monthDialog";
import {convertToGregorianYear} from "../utils/utils";
import useFetchWithAuth from "../hooks/useFetchWithAuth";

const CloseMonth = () => {
    const defaultJoys = {
        wedding: '',
        birth: '',
        twins: '',
        barMitzvah: '',
        driver: ''
    }
    const [year, setYear] = useState(getJewishDate(new Date()).year)
    const [month, setMonth] = useState(toHebrewJewishDate(getJewishDate(new Date())).monthName)
    const [rowData, setRowData] = useState([])
    const [open, setOpen] = useState(false);
    const [frameworks, setFrameworks] = useState([])
    const [groups, setGroups] = useState([])
    const [groupsData, setGroupsData] = useState([])
    const [frameworksHours, setFrameworksHours] = useState({})
    const handleOpen = () => setOpen(true);
    const [editOpen, setEditOpen] = useState(false)
    const [editData, setEditData] = useState(null);
    const [joys, setJoys] = useState({
        ...defaultJoys
    })

    const handleClose = async () => {
        setOpen(false);
        setYear(getJewishDate(new Date()).year);
        setMonth((toHebrewJewishDate(getJewishDate(new Date())).monthName))
        setFrameworksHours({})
        setGroupsData([])
        setJoys({...defaultJoys})
        await fetchData()
    };
    const fetchWithAuth = useFetchWithAuth();
    const {handleSubmit} = useForm({});


    const editHandleOpen = async (data) => {
        await fetchFrameworksHours(data.id)
        const dataToSave = {
            ...data,
            year: convertToGregorianYear(data.year)
        }
        await fetchGroupsData(data.id)
        setEditData(dataToSave);
        setYear(dataToSave.year)
        setMonth((dataToSave.monthName))
        setJoys({
            wedding: data.wedding,
            birth: data.birth,
            twins: data.twins,
            driver: data.driver,
            barMitzvah: data.barMitzvah
        })
        setEditOpen(true)
    }

    const fetchFrameworksHours = async (id) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/max-hours/get-by-month/${id}`, {
                method: 'GET'
            });

            if (response.ok) {
                const data = await response.json();
                if (Array.isArray(data)) {
                    const aframeworksHours = Object.assign(
                        {},
                        ...data.map(curr => ({[curr.framework_id]: curr.max_hours}))
                    );

                    await setFrameworksHours(aframeworksHours); // update state with transformed data
                } else {
                    console.error("Data is not an array.");
                }

            }
        } catch (ex) {
            console.error("Error on fetch data", ex);
        }
    }

    const fetchGroupsData = async (monthId) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/groupMonth/month/${monthId}`, {
                method: 'GET'
            });

            if (response.ok) {
                const data = await response.json();
                setGroupsData(data);
            }
        } catch (ex) {
            console.error("Error on fetch data", ex);
        }
    }

    const editHandleClose = async () => {
        setEditOpen(false)
        setOpen(false)
        setYear(getJewishDate(new Date()).year);
        setMonth((toHebrewJewishDate(getJewishDate(new Date())).monthName))
        setFrameworksHours({})
        setJoys({...defaultJoys})
        await fetchData()
    };

    const FrameworkComponents = (params) => {
        return (
            <IconButton aria-label="delete" className="icon-button"
                        onClick={() => deleteCloseMonth(params.data.id)}>
                <DeleteIcon/>
            </IconButton>
        )
    };

    const deleteCloseMonth = async (id) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/closeMonth/${id}`, {
                method: "DELETE"
            })
            if (response.status === 204) {
                setRowData(rowData.filter(rd => rd.id !== id))
            }
            await fetchData()
        } catch {
            console.error("error on delete month")
        }
    }

    const defaultColDef = {
        width: 150, // Default width for columns
        sortable: true, // Default sorting enabled for columns
        filter: true, // Default filtering enabled for columns
    };
    const gridOptions = {
        enableRtl: true,
    };
    const IsOpenMonth = (params) => {
        const openMonth = async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/closeMonth/open/${params.data.id}`, {
                    method: 'PUT',
                })
                if (response.status === 200) {
                    setRowData(rowData.map(d => {
                        if (d.id === params.data.id)
                            return ({...d, isClosed: 0})
                        else return d
                    }))
                    await fetchData(); // Refresh data after opening month
                }
            } catch {
                console.error("error in open month")
            }
        }

        const closeMonth = async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/closeMonth/close/${params.data.id}`, {
                    method: 'PUT'
                })
                if (response.status === 200) {
                    setRowData(rowData.map(d => {
                        if (d.id === params.data.id)
                            return ({...d, isClosed: 1})
                        else return d
                    }))
                    await fetchData(); // Refresh data after closing month
                }
            } catch {
                console.error("error in close month")
            }
        }

        return (
            <IconButton onClick={params.data.isClosed ? openMonth : closeMonth} disabled={!params.data.isClosed}>
                {params.data.isClosed ? <Lock></Lock> : <LockOpen></LockOpen>}
            </IconButton>
        )
    }
    const EditComponent = (params) => {
        return <IconButton aria-label="delete" className="icon-button" onClick={async () => {
            await editHandleOpen(params.data);
        }}>
            <Edit></Edit>
        </IconButton>
    }

    const columnDefs = [
        {headerName: "שנה", field: "year", sortable: true, filter: true,},
        {headerName: "חודש", field: "monthName", sortable: true, filter: true,},
        {
            headerName: "",
            field: "isClosed",
            cellRenderer: IsOpenMonth,
            sortable: false,
            filter: false,
            width: "80px",
        },
        {
            headerName: "",
            cellRenderer: EditComponent,
            width: "80px",
            sortable: false,
            filter: false,
        },
        {
            headerName: "",
            cellRenderer: FrameworkComponents,
            width: "80px",
            sortable: false,
            filter: false,
        }
    ]

    const fetchData = useCallback(async () => {
            try {
                const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/closeMonth`, {
                    method: "GET",
                })
                if (response.ok) {
                    const data = await response.json()
                    setRowData(data)
                }

                const responseFrameworks = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/frameworks`, {
                    method: "GET",
                })
                if (responseFrameworks.ok) {
                    const data = await responseFrameworks.json()
                    const frames = data.map(frame => {
                        return {
                            id: frame.id,
                            name: frame.nameHe
                        }
                    }).filter(f => f.name !== "יום")
                    setFrameworks(frames)
                } else {
                    console.error("error on fetch frameworks")
                }
                const responseGroups = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/groups`, {
                    method: "GET",
                })
                if (responseGroups.ok) {
                    const data = await responseGroups.json()
                    setGroups(data)
                } else {
                    console.error("error on fetch groups")
                }

            } catch {
                console.error("error on fetch data")
            }
        }, []
    )

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <div style={{direction: "rtl"}}>
            <Button style={{maxHeight: "30px", direction: "rtl", marginBottom: "5px"}} variant="contained"
                    onClick={handleOpen}>פתיחת חודש </Button>

            <Modal
                open={open}
                onClose={handleClose}
                style={{padding: '100px'}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {open? <MonthDialog
                    handleClose={handleClose}
                    frameworks={frameworks}
                    frameworksHours={frameworksHours}
                    setFrameworksHours={setFrameworksHours}
                    month={month}
                    setMonth={setMonth}
                    year={year}
                    setYear={setYear}
                    handleSubmit={handleSubmit}
                    rowData={rowData}
                    setRowData={setRowData}
                    mode="create"
                    groups={groups}
                    setGroups={setGroups}
                    groupsData={groupsData}
                    setGroupsData={setGroupsData}
                    joys={joys}
                    setJoys={setJoys}
                /> : <></>}
            </Modal>

            <Modal
                open={editOpen}
                onClose={editHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {
                    editOpen?( <MonthDialog
                        handleClose={editHandleClose}
                        frameworks={frameworks}
                        frameworksHours={frameworksHours}
                        setFrameworksHours={setFrameworksHours}
                        month={editData?.monthName}
                        setMonth={setMonth}
                        year={editData?.year}
                        setYear={setYear}
                        handleSubmit={handleSubmit}
                        rowData={rowData}
                        setRowData={setRowData}
                        mode="edit"
                        editData={editData}
                        disable={true}
                        groups={groups}
                        setGroups={setGroups}
                        groupsData={groupsData}
                        setGroupsData={setGroupsData}
                        joys={joys}
                        setJoys={setJoys}
                    />):(<></>)
                }
            </Modal>
            <div className="ag-theme-alpine"
                 style={{height: 'calc(100vh - 170px)', width: '530px', direction: "rtl"}}>
                <AgGridReact enableCellTextSelection={true} ensureDomOrder={true} rowData={rowData}
                             columnDefs={columnDefs} gridOptions={gridOptions}
                             defaultColDef={defaultColDef}></AgGridReact>
            </div>
        </div>
    )
}
export default CloseMonth