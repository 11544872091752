import { TextField}  from "@mui/material";

export const TextFieldInput = ({label, value, onChange}) =>{
    return (
        <TextField
            style={{ width: '45%' , margin: 1, padding: 1}}
            label={label}
            value={value}
            type="number"
            required={true}
            variant="filled"
            onChange={onChange}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}