import './App.css';
import SignInForm from './Auth/SignInForm';
import Template from './Template'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {ChooseKollel} from "./chooseKollel/chooseKollel";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<ChooseKollel />}></Route>
        <Route path='/app' element={<Template />}></Route>
        <Route path='/login' element={<SignInForm />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
