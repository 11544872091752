import {TextFieldInput} from "./textFieldInput";
import React from "react";

export const JoysData = ({wedding, birth , twins, barMitzvah, driver, setJoys}) => {
    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}>
                <TextFieldInput label={'חתונה'} value={wedding} onChange={
                    (e) => {
                        const newValue = e.target.value;
                        setJoys((prev) => {
                            return {
                                ...prev,
                                wedding: +newValue
                            }
                        })
                    }
                }/>
                <TextFieldInput label={'לידה'} value={birth} onChange={
                    (e) => {
                        const newValue = e.target.value;
                        setJoys((prev) => {
                            return {
                                ...prev,
                                birth: +newValue
                            }
                        })
                    }
                }/>
                <TextFieldInput label={'תאומים'} value={twins} onChange={
                    (e) => {
                        const newValue = e.target.value;
                        setJoys((prev) => {
                            return {
                                ...prev,
                                twins: +newValue
                            }
                        })
                    }
                }/>
                <TextFieldInput label={'בר מצוה'} value={barMitzvah} onChange={
                    (e) => {
                        const newValue = e.target.value;
                        setJoys((prev) => {
                            return {
                                ...prev,
                                barMitzvah: +newValue
                            }
                        })
                    }
                }/>

            </div>
                
            <h4 style={{textAlign: 'center', width: '100%'}}>נסיעות</h4>
            <TextFieldInput label={'נהג'} value={driver} onChange={
                (e) => {
                    const newValue = e.target.value;
                    setJoys((prev) => {
                        return {
                            ...prev,
                            driver: +newValue
                        }
                    })
                }
            }/>
        </div>
    )
}