import React, {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Delete} from "@mui/icons-material";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import DeleteIcon from "@mui/icons-material/Delete";

export const DeleteGroupComponent = ({data, setFetchData, params}) => {
    const fetchWithAuth = useFetchWithAuth();
    const deleteGroup = async (group) => {
        let {data: data2} = {group }
        const id = data?.data?.id;
        console.log(data2)
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/groups/${id}`, {
                method: "delete"
            });

            if (response.ok) {
                setFetchData(true);
            }
        } catch (ex) {
            console.error("error on delete data");
        }
    };

    return (
        <IconButton aria-label="delete" className="icon-button"
                    onClick={() => deleteGroup(data)}>
            <DeleteIcon/>
        </IconButton>
    )
};