import {useNavigate} from "react-router";
import {getHeaders} from "../Auth/headers";

const useFetchWithAuth = () => {
    const navigate = useNavigate();

    return async (url, options) => {
        if (!localStorage.getItem('kollel')) {
            navigate('/');
            return null;
        }
        options.headers ={
            ...getHeaders(),
            ...options.headers
        }
        try {
            const response = await fetch(url, options);
            if (response.status === 403 || response.status === 401) {
                navigate("/login");
            }
            return response;
        } catch (error) {
            console.error("Error on fetching data:", error);
            return null;
        }
    };
};

export default useFetchWithAuth;