import React, { useEffect, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { GroupData } from './groupData';
import {GroupsTableData} from "./groupTableData";

export const GroupsMonthDialog = ({ groups, groupsData, setGroupsData, mode }) => {
    const [originalGroupsData, setOriginalGroupsData] = useState(JSON.parse(JSON.stringify(groupsData)) );
    const [eachGroupSetting, setEachGroupSetting] = useState(false);

    useEffect(() => {
        setOriginalGroupsData(()=> {
            return JSON.parse(JSON.stringify(groupsData));
        })

        return () => {
            setOriginalGroupsData([])
            setGroupsData([])
        };
    }, []);


    return (
        <div style={{
            paddingBottom: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={eachGroupSetting}
                        onChange={() => setEachGroupSetting(!eachGroupSetting)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                label="הגדר כל קבוצה בנפרד"
                labelPlacement="start"
            />
            {!eachGroupSetting ? (
                <GroupData
                    id={-1}
                    setGroupsData={setGroupsData}
                    allGroups={groups}
                    updateAll={true}
                />
            ) : (
                <GroupsTableData groups={groups} groupsData={originalGroupsData} setGroupsData={setGroupsData} mode={mode}/>
            )}
        </div>
    );
};