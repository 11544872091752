import CustomFilter from "../../filters/CustomFilter";
import {getHebrewMonth} from "../../utils/utils";
import CustomYearMonthFilter from "../../filters/CustomYearMonthFilter";
import {Button} from "@mui/material";
import React from "react";
import {useDayStore} from "../../store/dayStore";
import {observer} from "mobx-react";

export const DayFilters = observer(() => {
    const {dayStore} = useDayStore()

    const clearFilters = () => {
        dayStore.setDayFilters([])
    }

    const onFilterChanged = (field, filterValues, clear) => {

        if (field !== 'payment' && !Object.keys(filterValues).length)
            return
        dayStore.setDayFilters(
            dayStore.dayFilters.filter(({fieldName}) => fieldName !== field).concat([
                {fieldName: field, values:field !== 'payment'?
                        Object.keys(filterValues).filter(key => filterValues[key]):
                        {month: filterValues.month, year: filterValues.year}
                }
            ])
        );
    };

    return (
        <div>
            <div style={{display: "flex", zIndex: 2000}}>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    height: '100px',
                    alignContent: 'flex-start',
                    justifyContent: 'space-evenly'
                }}>
                    <div style={{'display': "flex", "position": "relative"}}>
                        <CustomFilter onFilterChanged={onFilterChanged} filterName={"בחר חודשים"} fieldName="monthName"
                                      filters={getHebrewMonth()} clearFilters={clearFilters}></CustomFilter>
                        <CustomFilter onFilterChanged={onFilterChanged} filterName={"בחר שנים"} fieldName="year"
                                      filters={dayStore.getYears} clearFilters={clearFilters}></CustomFilter>
                        <CustomFilter onFilterChanged={onFilterChanged} filterName={"בחר סוג דיווח"} fieldName="late"
                                      filters={["מאוחר", "רגיל"]} clearFilters={clearFilters}></CustomFilter>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                        <CustomYearMonthFilter onFilterChanged={onFilterChanged} filterName={"בחר חודש דיווח"}
                                               fieldName="payment"
                                               filters={{months: getHebrewMonth(), years: dayStore.getYears}}
                                               clearFilters={clearFilters} width={"512px "}/>
                        <Button style={{maxHeight: "30px", direction: "rtl", width: '163px'}} variant="contained"
                                onClick={() => clearFilters()} clearFilters={clearFilters}> נקה סינון</Button>
                    </div>
                </div>
            </div>
        </div>
    )
})