import { MenuItem } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import {useDayStore} from "../../../store/dayStore";
import {observe} from "mobx";
import {observer} from "mobx-react";
import CloseIcon from "@mui/icons-material/Close"; // Import the check icon

export const ApprovedCom = observer(({data}) => {
    const id = data.data.id
    const { updateTravel } = useDayStore();
    return (
        <MenuItem onClick={async () => {
            await updateTravel({id, approved: true})
        }} className='menuButton'>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <CheckIcon/>
                <p style={{margin: '0 0 0 8px'}}>אישור</p>
            </div>
        </MenuItem>
    );
})