import { MenuItem } from "@mui/material";
import React, { useState } from "react";
import Edit from "@mui/icons-material/Edit";
import { UpdateSumDialog } from "./UpdateSumDialog";

export const UpdateSum = ({data} ) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const {data:{id}} = data

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <MenuItem onClick={handleClickOpen} className='menuButton'>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Edit/>
                    <p style={{margin: '0 0 0 8px'}}> עדכון סכום </p>
                </div>
            </MenuItem>
            <UpdateSumDialog open={dialogOpen} onClose={handleDialogClose} id={id} />
        </>
    );
};