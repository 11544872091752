import {getHebrewMonth} from "../../utils/utils";
import {Button} from "@mui/material";
import React from "react";
import CustomFilter from "../../filters/CustomFilter";
import CustomYearMonthFilter from "../../filters/CustomYearMonthFilter";

export const Filters = ({years, frameworks, clearFilters, setClearFilters, filters, setFilters}) => {

    const filtering = (field, filterValues, clear) => {
        if (clear) {
            setFilters({});
            setClearFilters(false);
        } else {
            const notFieldFilter = Object.values(filterValues).every(value => !value);

            let filter = {
                ...filters,
                [field]: {filterValues}
            };

            if (notFieldFilter) {
                const {[field]: removed, ...rest} = filter
                filter = rest
            }

            setFilters(clear ? {} : filter);
        }
    };

    return (
        <div>
            <div style={{display: "flex", zIndex: 2}}>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    height: '100px',
                    alignContent: 'flex-start',
                    justifyContent: 'space-evenly'
                }}>
                    <div style={{'display': "flex", "position": "relative"}}>
                        <CustomFilter onFilterChanged={filtering} filterName={"בחר חודשים"} fieldName="month"
                                      filters={getHebrewMonth()} clearFilters={clearFilters}></CustomFilter>
                        <CustomFilter onFilterChanged={filtering} filterName={"בחר שנים"} fieldName="year"
                                      filters={years} clearFilters={clearFilters}></CustomFilter>
                        <CustomFilter onFilterChanged={filtering} filterName={"בחר סוג דיווח"} fieldName="isLate"
                                      filters={["מאוחר", "רגיל"]} clearFilters={clearFilters}></CustomFilter>
                        <CustomFilter onFilterChanged={filtering} filterName={"בחר מסגרת"} fieldName="framework"
                                      filters={frameworks} clearFilters={clearFilters}
                                      width={"200px "}></CustomFilter>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                        <CustomYearMonthFilter onFilterChanged={filtering} filterName={"בחר חודש דיווח"}
                                               fieldName="payment" filters={{months: getHebrewMonth(), years: years}}
                                               clearFilters={clearFilters} width={"512px "}/>
                        <Button style={{maxHeight: "30px", direction: "rtl", width: '163px'}} variant="contained"
                                onClick={() => setClearFilters(true)} clearFilters={clearFilters}> נקה סינון</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}