import React, { useState } from "react";
import { IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteCom } from "./delete";
import { ApprovedCom } from "./approve";
import { DisapprovedCom } from "./Disapproved";
import { UpdateSum } from "./updateSum";

const ITEM_HEIGHT = 48;

export const MenuOptions = ({ data }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className='icon-button'
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '30ch',
                            direction: 'rtl'
                        }
                    }
                }}
            >
                <ApprovedCom  data={data} />
                <DisapprovedCom handleClose={handleClose} data={data} />
                <UpdateSum handleClose={handleClose} data={data} />
                <DeleteCom handleClose={handleClose} data={data} />
            </Menu>
        </div>
    );
};