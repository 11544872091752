import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Typography, Container, CssBaseline, Paper, Box } from '@mui/material';
import {getLoginHeaders} from './headers';
import CustomToolbar from '../toolBar/toolBar';

const SignInForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigator = useNavigate();

    const handleSignIn = async () => {
        setError(false);
        setSuccess(false);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/signin`, {
                method: 'POST',
                headers: getLoginHeaders(),
                body: JSON.stringify({ username, password })
            });
            setPassword("");
            setUsername("");

            if (!response.ok) {
                setError(true);
                throw  new Error('Sign-in failed');
            }

            const { token } = await response.json();
            localStorage.setItem('token', token); // Store the token in local storage
            setSuccess(true);
            navigator('/app');

        } catch (error) {
            console.error('Sign-in failed:', error);
        }
    };

    return (
        <>
            <CustomToolbar />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}
            >
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Paper elevation={3} style={{ padding: 20, marginTop: 50 }}>
                        <Typography component="h1" variant="h5" align="center">
                            התחברות
                        </Typography>
                        <form>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="שם משתמש"
                                name="username"
                                autoComplete="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="סיסמא"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                onClick={handleSignIn}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                התחברות
                            </Button>
                            {success && <Typography variant="body2" color="success" align="center">התחברת בהצלחה!</Typography>}
                            {error && <Typography variant="body2" color="error" align="center">שם משתמש /סיסמא לא נכונים</Typography>}
                            {/* <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link to={'/signup'} variant="body2">
                                        לא רשום ? הרשם עכשיו
                                    </Link>
                                </Grid>
                            </Grid> */}
                        </form>
                    </Paper>
                </Container>
            </Box>
        </>
    );
};

export default SignInForm;