import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import {observer} from "mobx-react";

const GenericTable = observer(({ rowData, columnDefs, fetchData , clear = ()=>{}}) => {
    const gridRef = useRef();
    const containerRef = useRef();
    const [loading, setLoading] = useState(true);

    const memoizedFetchData = useCallback(async () => {
        await fetchData();
        setLoading(false);
    }, [fetchData]);

    useEffect(() => {
        memoizedFetchData();
    }, []);

    useEffect(() => {
        return () => {
            clear()
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onGridReady = (params) => {
        gridRef.current = params.api;
        autoSizeAllColumns(params.api);
        setColumnsWidth(params.api);
    };

    const handleResize = () => {
        if (gridRef.current) {
            setColumnsWidth(gridRef.current);
        }
    };

    const autoSizeAllColumns = (api) => {
        const allColumnIds = api.getColumnDefs().map(colDef => colDef.field);
        api.autoSizeColumns(allColumnIds, false);
    };

    const setColumnsWidth = (api) => {
        const allColumns = api.getColumnDefs();
        const columnState = api.getColumnState();
        const sumWidth = columnState.reduce((sum, col) => sum + col.width, 0);
        const containerWidth = containerRef.current.getBoundingClientRect().width;

        if (sumWidth < containerWidth) {
            const mapPercent = {};
            allColumns.forEach(col => {
                mapPercent[col.field] = col.width / sumWidth;
            });

            const newColumnDefs = columnState.map(col => {
                const originalColDef = allColumns.find(c => c.field === col.colId);
                return {
                    ...originalColDef,
                    width: mapPercent[col.colId] * containerWidth
                };
            });

            api.setColumnDefs(newColumnDefs);
        }
    };

    const gridOptions = useMemo(() => ({
        enableRtl: true,
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        }
    }), []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div ref={containerRef} className="ag-theme-alpine table-container" style={{ height: 'calc(100vh - 170px)', zIndex: 0 }}>
            <AgGridReact
                enableCellTextSelection={true}
                ensureDomOrder={true}
                rowData={rowData}
                columnDefs={columnDefs}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
            ></AgGridReact>
        </div>
    );
});

export default GenericTable;