import Table from "./table";
import {TravelFilters} from "./filters";

export const TravelsApprove = () => {

    return (
        <>
            <TravelFilters/>
            <Table/>
        </>
    );
}