import React, {useState}  from 'react';
import {Button, IconButton, Box, TextField, MenuItem} from '@mui/material';
import Close from '@mui/icons-material/Close';
import {convertToHebrewLetters} from '../utils/utils';
import {ErrorMassage} from '../errorMassage';
import useFetchWithAuth from "../hooks/useFetchWithAuth";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    high: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    direction: "rtl",
    padding: '10px',
    zIndex: 1000
};

export function GroupDialog({
                                handleClose,
                                mode,
                                params
                            }) {
    const {data:{name, time, id} } = params || {data: {name: '', time: '', id: ''}};
    const [uname, setUName] = useState(name);
    const [uTime, setUTime] = useState(time);
    const [errorPost, setErrorPost] = useState(false);
    const [errorMassage, setMassage] = useState('');
    const fetchWithAuth = useFetchWithAuth();
    const onSubmit = async (data) => {
        try {
            data.preventDefault();
            const url = mode === 'edit' ? `${process.env.REACT_APP_BASE_URL}/groups/${id}` : `${process.env.REACT_APP_BASE_URL}/groups`;
            const method = mode === 'edit' ? 'PUT' : 'POST';
            const response = await fetchWithAuth(url, {
                method,
                body: JSON.stringify({
                    time: uTime,
                    name: uname
                })
            });
            if (!response.ok) {
                setErrorPost(true);
                try {
                    const error = await response.text();
                    if (error === 'time already closed')
                    {
                        setMassage(`חודש ${time + ' ' + convertToHebrewLetters(name)}  כבר קיים במערכת`);
                    }
                } catch {
                    setMassage("ארעה תקלה בשמירת הנתונים");
                }
            }
            else {
                handleClose();
            }
        } catch {
            console.error("error on submit");
        }
    };
    return (
        <Box sx={style} >
            <IconButton onClick={handleClose} style={{marginBottom: '10px'}}>
                <Close/>
            </IconButton>
            <form onSubmit={(onSubmit)}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                    height: '50%'
                }}>
                    <TextField
                        label="שם"
                        value={uname}
                        onChange={(e) => setUName(e.target.value)}
                        variant="filled"
                        style={{width: '45%', paddingBottom: '10px'}}
                    >
                    </TextField>
                    <TextField
                        select
                        label="סדר"
                        variant="filled"
                        value={uTime}
                        onChange={(e) => setUTime(e.target.value)}
                        style={{width: '45%'}}
                    >
                        {['ב׳','א׳' ].map((name) => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                <ErrorMassage error={errorMassage} setOpen={setErrorPost} open={errorPost}/>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <Button type="submit" style={{maxHeight: '30px', direction: 'rtl'}}
                            variant="contained">{mode === 'edit' ? 'ערוך' : 'פתח'}</Button>
                </div>
            </form>
        </Box>
    );
}