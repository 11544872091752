import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    header: {
        backgroundColor: '#f5f5f5',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    row: {
        '&:hover': {
            backgroundColor: '#f1f1f1',
        },
    },
    cell: {
        textAlign: 'center',
        border: '1px solid #ddd'
    },
    noArrows: {
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
            appearance: 'textfield', // Ensure it works across all browsers
        },
    },
});

export const GroupsTableData = ({ groups, groupsData, setGroupsData }) => {
    const classes = useStyles();
    const [currentGroupsData, setCurrentGroupsData] = useState([]);

    useEffect(() => {
        if (!groupsData || !groupsData.length) {
            const emptyGroups = groups.map(group => ({
                group_id: group.id,
            }));
            setGroupsData(emptyGroups);
            setCurrentGroupsData(emptyGroups);
        } else {
            setCurrentGroupsData(groupsData);
            setGroupsData(groupsData);
        }
    }, [groups, groupsData, setGroupsData]);

    const handleInputChange = (groupId, field, value) => {
        setCurrentGroupsData(prevData => {
            const updatedData = prevData.map(data =>
                data.group_id === groupId ? { ...data, [field]: value } : data
            );
            setGroupsData(updatedData);
            return updatedData;
        });
    };

    const getGroupData = (groupId) => {
        return currentGroupsData?.find(gd => gd.group_id === groupId) || {};
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead className={classes.header}>
                    <TableRow>
                        <TableCell className={classes.cell}>קבוצה</TableCell>
                        <TableCell className={classes.cell}>סדר</TableCell>
                        <TableCell className={classes.cell}>מקסימום חבורות בכתב</TableCell>
                        <TableCell className={classes.cell}>תשלום לחבורה בכתב</TableCell>
                        <TableCell className={classes.cell}>מקסימום חבורות בע״פ</TableCell>
                        <TableCell className={classes.cell}>תשלום לחבורה בע״פ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map(g => {
                        const groupData = getGroupData(g.id);
                        return (
                            <TableRow key={g.id} className={classes.row}>
                                <TableCell className={classes.cell}>{g.name}</TableCell>
                                <TableCell className={classes.cell}>{g.time}</TableCell>
                                <TableCell className={classes.cell}>
                                    <TextField
                                        className={classes.noArrows}
                                        value={groupData.maxWriteSpeech || ''}
                                        onChange={(e) => handleInputChange(g.id, 'maxWriteSpeech', e.target.value)}
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        required
                                        type="number"
                                    />
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <TextField
                                        className={classes.noArrows}
                                        value={groupData.writeSpeechRate || ''}
                                        onChange={(e) => handleInputChange(g.id, 'writeSpeechRate', e.target.value)}
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        required
                                        type="number"
                                    />
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <TextField
                                        className={classes.noArrows}
                                        value={groupData.maxTellSpeech || ''}
                                        onChange={(e) => handleInputChange(g.id, 'maxTellSpeech', e.target.value)}
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        required
                                        type="number"
                                    />
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <TextField
                                        className={classes.noArrows}
                                        value={groupData.tellSpeechRate || ''}
                                        onChange={(e) => handleInputChange(g.id, 'tellSpeechRate', e.target.value)}
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        required
                                        type="number"
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};