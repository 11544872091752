import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Modal, Box } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FrameworkForm from './FrameworkForm';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Close from '@mui/icons-material/Close';
import { Edit, Delete } from '@mui/icons-material';
import useFetchWithAuth from "../hooks/useFetchWithAuth";
const ITEM_HEIGHT = 48;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    direction: "rtl"
};

const FrameworksTable = () => {
    const [rowData, setRowData] = useState([]);
    const [editData, setEditData] = useState({})
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = async () => {
        setOpen(false)
        setEditData({})
        await fetchData()
    };
    const fetchWithAuth = useFetchWithAuth();

    const FrameworkDeleteComponents = (params) => {

        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    className='icon-button'
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            direction: "rtl"
                        },
                    }}
                >

                    <MenuItem onClick={() => { handleClose(); handleDelete(params.data) }} style={{ direction: "rtl", height: "40px" }}>
                        <Delete></Delete>
                        <p style={{ width: "100%", textAlign: "center" }}>  מחיקה  </p>
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); handleEdit(params.data) }} style={{ direction: "rtl", height: "40px" }}>
                        <Edit></Edit>
                        <p style={{ width: "100%", textAlign: "center" }}> עריכה </p>

                    </MenuItem>

                </Menu>
            </div>)
    }
    const gridOptions = {
        enableRtl: true
    }
    const [columnDefs, setColumnDefs] = useState([
        { headerName: "שם מסגרת", field: "name", sortable: true, filter: true, },
        { headerName: "רצופות", field: "consecutiveSize", sortable: true, filter: true, },
        { headerName: "הודעה מהגבאי", field: "message", sortable: true, filter: true, width: "450" },
        {
            headerName: "",
            field: "delete",
            cellRenderer: FrameworkDeleteComponents,
            width: "60"
        },
    ])

    const handleEdit = (rowData) => {

        setEditData({ framework: rowData, isEditing: true })
        handleOpen()
    };

    const handleDelete = async (rowData) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/frameworks/${rowData.id}`, {
                method: "DELETE"
            })

            if (response.ok) {
                setRowData(prev => {
                    let newData = [...prev];
                    newData.splice(prev.findIndex(item => item.id === rowData.id), 1)
                    return newData
                })
            }
            await fetchData()
        }
        catch {
            console.error("error on delete framework")
        }

    }

    const fetchData = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/frameworks`, {
                method: "GET"
            })
            if (response.ok) {
                const data = await response.json()
                const rows = data.map((row) => {
                    return { ...row, name: `${row.nameHe}` }
                })
                setRowData(rows)
            }
        }
        catch {
            console.error("error on fetch data")
        }
    }


    useEffect(() => {
        fetchData()

    }, [])

    return <div>
        <Button style={{ maxHeight: "30px", direction: "rtl", marginBottom: "5px" }} variant="contained" onClick={handleOpen} > הוספת מסגרת</Button>
        <Modal
            open={open}
            onClose={() => { }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={style}>
                {/* <button onClick={handleClose}>X</button> */}
                <IconButton onClick={handleClose} style={{ marginBottom: "10px" }} ><Close></Close></IconButton>
                <FrameworkForm close={handleClose} {...editData} setRowData={setRowData} rowData={rowData}></FrameworkForm>
            </Box>
        </Modal>
        <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 170px)', width: '100%' }}>
            <AgGridReact enableCellTextSelection={true} ensureDomOrder={true} rowData={rowData} columnDefs={columnDefs} gridOptions={gridOptions} defaultColDef={{ width: 150 }}></AgGridReact>
        </div>
    </div>
}

export default FrameworksTable