import {useState, useEffect} from 'react';
import {TextFieldInput} from '../textFieldInput';

export const GroupData = ({
                              maxWriteSpeech,
                              maxTellSpeech,
                              writeSpeechRate,
                              tellSpeechRate,
                              name,
                              time,
                              id,
                              setGroupsData,
                              allGroups,
                              updateAll
                          }) => {
    const [maxWriteSpeechState, setMaxWriteSpeechState] = useState(maxWriteSpeech || '');
    const [maxTellSpeechState, setMaxTellSpeechState] = useState(maxTellSpeech || '');
    const [writeSpeechRateState, setWriteSpeechRateState] = useState(writeSpeechRate || '');
    const [tellSpeechRateState, setTellSpeechRateState] = useState(tellSpeechRate || '');

    useEffect(() => {
        setGroupsData((prev) => {
            const updatedGroup = {
                group_id: id,
                maxWriteSpeech: maxWriteSpeechState,
                maxTellSpeech: maxTellSpeechState,
                writeSpeechRate: writeSpeechRateState,
                tellSpeechRate: tellSpeechRateState
            };

            if (updateAll) {
                return allGroups.map(g => ({...updatedGroup, group_id: g.id}));
            }

            const newGroupsData = prev.map(g => g.group_id === id ? updatedGroup : g);

            if (!newGroupsData.some(g => g.group_id === id)) {
                newGroupsData.push(updatedGroup);
            }

            return newGroupsData;
        });
    }, [maxWriteSpeechState, maxTellSpeechState, writeSpeechRateState, tellSpeechRateState]);

    return (
        <div style={{
            paddingBottom: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {name && time && <h4>{name} סדר {time}</h4>}
            <TextFieldInput
                label={'מקסימום חבורות בכתב'}
                value={maxWriteSpeechState}
                onChange={(e) => {
                    const newValue = e.target.value;
                    setMaxWriteSpeechState(newValue);
                }}/>
            <TextFieldInput
                label={'תשלום לחבורה בכתב'}
                value={writeSpeechRateState}
                onChange={(e) => {
                    const newValue = e.target.value;
                    setWriteSpeechRateState(newValue);
                }}
            />
            <TextFieldInput
                label={'מקסימום חבורות בע״פ'}
                value={maxTellSpeechState}
                onChange={(e) => {
                    const newValue = e.target.value;
                    setMaxTellSpeechState(newValue);
                }}
            />
            <TextFieldInput
                label={'תשלום לחבורה בע״פ'}
                value={tellSpeechRateState}
                onChange={(e) => {
                    const newValue = e.target.value;
                    setTellSpeechRateState(newValue);
                }}
            />

        </div>
    );
};